/*=========================================================================================
File Name: Error.vue
Description: 404 Error Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Not Found Area Component -->
  <not-found-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import NotFoundArea from "../../components/NotFoundArea";
export default {
  name: "ErrorPage",
  components: {NotFoundArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Error 404",
        pageTitle: "Error 404"
      }
    }
  }
}
</script>

<style scoped>

</style>